/* Profile Modal Styling */
.profile-modal {
    display: flex;
    justify-content: start;
    align-items: start;
    top: 0;
    left: 0;
    width: 100%;
   
  }
  label {
    text-align: start;
  }
  /* .edit-icon{
    font-size: 20px;
    color: #646464;
    cursor: pointer;
  } */
  /* Profile Card */  
  .profile-card {
    position: relative;
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    text-align: left;
}

  /* Header Section */
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    padding-bottom: 15px;
  }

  .profile-header h2 {
    font-size: 20px;
    font-weight: bold;
  }
  
  .close-icon {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    color: gray;
    cursor: pointer;
    visibility: visible; /* Ensure it is always visible */
    opacity: 1; /* Make it fully visible */
    transition: color 0.3s ease;
}

.close-icon:hover {
    color: black; /* Slight color change on hover */
}

  /* Profile Image Section */
  .profile-image-section {
    text-align: center;
    margin-bottom: 20px;
  } 
  
  .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: none
  }
  
  .upload-text {
    color: #6a0dad;
    font-size: 14px;
    cursor: pointer;
    display: block;
    margin-top: 5px;
  }
  
  /* Profile Form Fields */
  .profile-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .profile-field {
    display: flex;
    flex-direction: column;
  }
  
  .profile-label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .field-value {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
  }
  
  .disabled {
    background: #f0f0f0;
    cursor: not-allowed;
  }
  
  /* Action Buttons */
  .profile-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .cancel-btn {
    background: white;
    border: 1px solid #000;
    padding: 12px 18px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .save-btn {
    background: #6a0dad;
    color: white;
    padding: 12px 15px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    font-size: 14px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .profile-card {
      width: 90%;
    }
  }
  