
.container {
    width: 90%;
    max-width: 600px;
    max-height: 85vh; 
    background: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: auto; 
    transition: height 0.3s ease-in-out;
}

.category-options,
.flavor-options {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    max-height: 200px; 
    overflow-y: auto; 
    padding-right: 5px;
}

.category-box,
.flavor-box {
    width: 48%;
    height: 90px;
    border: 3px solid #8B4513;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-bottom: 10px;
}

.category-box.selected,
.flavor-box.selected {
    background: #FF5722;
    color: white;
    border-color: #D84315;
}

/* 📱 Mobile View */
@media (max-width: 650px) {
    .container {
        width: 95%;
        max-height: 90vh; 
        overflow-y: auto;
    }

    .category-box,
    .flavor-box {
        width: 100%; 
        height: 70px;
    }

    .category-options,
    .flavor-options {
        max-height: 220px; 
        overflow-y: auto; 
    }
}
