
/* Reset margin & padding */
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}


.user-avatar{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}
/* Home Container */
.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px auto; 
    width: 90%; 
    max-width: 1100px; /* Keep max-width to prevent stretching */
    text-align: center;
}

.header-row {
    /* width: 100%; */
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    margin-top: 15px;
}


/* Background Section */
.background-section {
    position: relative;
    width: 100%;
    height: 70vh;
    background-image: url("../../../public/assets/bg.png"); /* Background Image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(153, 152, 152, 0.1); /* Black Transparent Overlay */
    z-index: 1;
}

.heading-text {
    font-size: 30px;
    font-weight: bold;
    color: rgb(5, 102, 5);
}

.explore-btn {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 700;
    color: black;
    background: none;
    border: none;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid orangered;
    position: relative;
    z-index: 2;
}


.login-text {
    text-decoration: none;
    color: black;
    font-size: 20px;
    font-weight: 500;
    margin: 10px;
}
.login-section {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    
}
/* Login Icon */
.login-icon {
    font-size: 20px;
    color: black;
}


/* ✅ Responsive Design */
@media screen and (max-width: 768px) {
   

    .background-section {
        height: 50vh;
    }

    .heading-text {
        font-size: 24px;
    }

    .search-bar-container {
        max-width: 90%;
    }
    .header-row {
        padding: 15px 0;
    }

    .explore-btn {
        font-size: 16px;
    }

    .login-text {
        font-size: 16px;
    }

    .login-icon {
        font-size: 18px;
    }
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 10px; 
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px; 
    width: 90%; 
    font-weight: 500;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: start;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}
  

@media screen and (max-width: 768px) {
    .modal-content {
        width: 95%; 
        padding: 15px;
    }

    .modal-close {
        font-size: 18px;
    }
}

/* ✅ Extra Small Screens */
@media screen and (max-width: 480px) {
    .modal-content {
        width: 100%;
        border-radius: 0;
        padding: 10px;
    }

    .modal-close {
        font-size: 16px;
    }
}