.custom-card {
  border-radius: 15px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  max-width: 1000px;
  background-color: rgb(239 238 238);
  margin: auto;
  border: none;
}

/* Image Centering */
.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}

/* Dish Name */
.dish-name {
  font-size: 25px;
  font-weight: bold;
  color: black;
  text-align: center;
}

/* Hotel Name & Address */
.hotel-name,
.address,
.location {
  font-size: 18px;
  color: gray;
  font-weight: 500;
  text-align: center;
}

/* Description */
.hotel-description {
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-align: center;
}


.get-direction-btn {
  font-size: 16px;
  background-color: paleturquoise !important;
  border-radius: 20px;
  padding: 10px 20px;
}

.get-direction-btn:focus,
.get-direction-btn:active {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}


/* Centering Total Votes Button */
.vote-count-btn {
  background-color: #d0d0d0;
  font-size: 18px;
  font-weight: bold;
  border-radius: 30px;
  margin: 20px auto;
  width: 25%;
  display: block;
  padding: 4px;
  border: none;
  text-align: center;
}

.back-arrow {
  display: none;
  position: absolute;
  top: 20px;
  left: 15px;
  font-size: 24px;
  cursor: pointer;
  color: black;
  z-index: 10;
}
.local-votes{
  font-size: 15px;
  color: rgb(71, 71, 71);
}

.container-fluid {
  position: relative;
}

@media (max-width: 768px) {
  .back-arrow {
    display: block;
  }
}