.rank-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #FFF3CD; /* Light background */
}

.rank-content {
    max-width: 850px;
    background: white;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    text-align: justify;
}

.rank-content h2 {
    text-align: center;
    color: #8B4513;
    font-size: 26px;
    margin-bottom: 20px;
}

.rank-content p {
    font-size: 18px;
    line-height: 1.8;
    color: #4E342E;
    margin-bottom: 20px;
}

.rank-content strong {
    color: #D84315;
    font-weight: 500;
}

.highlight {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #D84315;
    margin-top: 20px;
}

/* Center Button */
.button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

/* Explore Button */
.explores-btn {
    background: #34A853;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    font-weight: bold;
}

/* Back Arrow - Hidden by default */
.back-arrow {
    display: none;
    position: absolute;
    top: 20px;
    left: 25px;
    font-size: 22px;
    cursor: pointer;
    color: #8B4513; /* Brown color */
}

/* Show back arrow only on mobile screens */
@media (max-width: 768px) {
    .back-arrow {
        display: block;
    }
}


